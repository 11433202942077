import apiClient from "../index";

const pushCloudRepository = (data) => apiClient.post("/be/management-tool/git/cloudRepository/push", data)

const getListIssueType = (data) => apiClient.post("/be/management-tool/jira/listIssueType", data)

const createIssueJira = (data) => apiClient.post("/be/management-tool/jira/createIssue", data)

const attachReportJira = (data) => apiClient.post("/be/management-tool/jira/attachReportOnIssue", data,{
  headers: { "Content-Type": "multipart/form-data" }
})

const getTickets = (data) => apiClient.post("/be/management-tool/jira/getTickets", data)

export {
  pushCloudRepository,
  getListIssueType,
  createIssueJira,
  attachReportJira,
  getTickets
};
