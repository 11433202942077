import React, {useEffect, useRef, useState} from "react";
import { Navigate } from "react-router-dom";
import useAuth from "utils/useAuth";
// Argon Dashboard 2 PRO MUI components
// material react table
import Header from "components/Header";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Footer from "components/Footer";
import { GenerativeTestTable } from "./GenerativeTestTable";
import { GenerativeTestFileUploader } from "./GenerativeTestFileUploader";
import {UsersApi} from "../../../api/BackendApi/Users";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import ArgonBox from "../../../components/ArgonBox";
import Grid from "@mui/material/Grid";
import ArgonTypography from "../../../components/ArgonTypography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import ArgonAvatar from "../../../components/ArgonAvatar";
import colors from "assets/theme/base/colors";
import Zoom from '@mui/material/Zoom';
import logoDBTableGIF from "assets/gif/database-table.gif";
import logoDBTablePNG from "assets/images/icons/database-table.png";
import logoBoxingGIF from "assets/gif/boxing.gif";
import logoBoxingPNG from "assets/images/icons/boxing.png";
import logoManagementToolGIF from "assets/gif/management-consulting.gif";
import logoManagementToolPNG from "assets/images/icons/management-consulting.png";
import {Backdrop, Modal, Paper} from "@mui/material";
import {AutoFixHigh, CheckCircle, Refresh} from "@mui/icons-material";
import {DataLakeInfo} from "./DataLakeInfo";
import {ManagementToolInfo} from "./ManagementToolInfo";
import ArgonProgress from "../../../components/ArgonProgress";
import {getAllIntegration} from "../../../api/BackendApi/Account";
import ArgonButton from "../../../components/ArgonButton";
import ArgonSelect from "../../../components/ArgonSelect";


export function GenerativeTestContent() {

	// * STATES of generative test page 
	const isLoggedIn = useAuth().ensureLoggedIn();
	const [user, setUser] = useState(null);
	const [userIntegrations, setUserIntegrations] = useState([]);

	const [loading, setLoading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(-1);
	const [tests, setTests] = useState([]);

	const [openModalSendButton, setOpenModalSendButton] = useState(false);
	const [bodyGenerate, setBodyGenerate] = useState(false);
	const [bodyRefresh, setBodyRefresh] = useState(false);

	const [cardUploadSelected, setCardUploadSelected] = useState(false);
	const [cardDataLakeSelected, setCardDataLakeSelected] = useState(false);
	const [cardManagementToolSelected, setCardManagementToolSelected] = useState(false);

	const [uploadSend, setUploadSend] = useState(false);
	const [datalakeSend, setDatalakeSend] = useState(false);
	const [managementSend, setManagementSend] = useState(false);

	const [generateSend, setGenerateSend] = useState(false);
	const [generativeTestError, setGenerativeTestError] = useState(false);
	const [generateType, setGenerateType] = useState(null);

	const dropzoneFiles= useRef([]);
	const jiraFiles= useRef([]);
	const figmaFiles= useRef([]);
	const adoFiles= useRef([]);

	const [connectedJira, setConnectedJira] = useState(false);
	const [dataJira, setDataJira] = useState([]);
	const [projectsJira, setProjectsJira] = useState([]);

	const [connectedFigma, setConnectedFigma] = useState(false);
	const [dataFigma, setDataFigma] = useState([]);

	const [connectedADO, setConnectedADO] = useState(false);
	const [dataADO, setDataADO] = useState([]);

	const { success } = colors;

	useEffect(() => {
		async function loadData() {
			let response = await UsersApi.getMe();
			setUser(response.data.user);
			let getIntegration = await getAllIntegration(response.data.user);
			setUserIntegrations(getIntegration.data.account);
		}
		loadData().then(r => console.log("User loaded"));
	}, []);

	if (!isLoggedIn) {
		return <Navigate replace to="/sign-in" />;
	}

	const onCardClick = (index) => {
		switch (index) {
			case "Upload":
				setCardUploadSelected(!cardUploadSelected);
				break;
			case "Data Lake":
				setCardDataLakeSelected(!cardDataLakeSelected);
				break;
			case "Management Tool":
				setCardManagementToolSelected(!cardManagementToolSelected);
				break;
			default:
				break;
		}
	}

	const setChangeSendType = ({ value }) => {
		setGenerateType(value);
	}

	const sendButtonAction = () => {
		fetchData();
	}

	const refreshPage = () => {
		setUploadSend(false);
		setManagementSend(false)
		dropzoneFiles.current = [];
		jiraFiles.current = [];
		figmaFiles.current = [];
		adoFiles.current = [];
		handleCloseModal();
	}

	const handleBack = () => {
		setGenerativeTestError(false);
		setGenerateSend(false);
	};

	const fetchData = () => {
		handleCloseModal();
		setGenerateSend(true);
	}

	const handleCloseModal = () => {
		setOpenModalSendButton(false);
		setBodyRefresh(false);
		setBodyGenerate(false);
	}

	const handleOpenModal = () => {
		setBodyRefresh(false);
		setBodyGenerate(true);
		setOpenModalSendButton(true);
		setGenerateType(null);
	}

	const handleOpenRefreshModal = () => {
		setBodyRefresh(true);
		setBodyGenerate(false);
		setOpenModalSendButton(true);
	}

	return (
		<DashboardLayout>
			<Header />
			<ArgonBox mt={10} sx={(cardDataLakeSelected || cardManagementToolSelected || cardUploadSelected) ? {display: "none"} : {}}>
				<Card>
					<Grid container p={1}>
						<Grid item xs={12} textAlign="center">
							<ArgonTypography variant="h2">Generative Test</ArgonTypography>
						</Grid>
					</Grid>
				</Card>
			</ArgonBox>
			<ArgonBox mt={10} mb={10} sx={generateSend ? {display: "none"} : {}}>
				<ArgonBox>
					<Grid container justifyContent="center" spacing={12}>
						<Grid item sx={(cardDataLakeSelected || cardManagementToolSelected || cardUploadSelected) ? {display: "none"} : {}}>
							<Card sx={ uploadSend ? {outline: 1, outlineColor: "primary.main"} : {}} onClick={() => onCardClick("Upload")}>
								<CardActionArea>
									<CardContent>
										<ArgonBox >
											<Grid container alignItems="center" spacing={2} direction="column">
												<Grid item xs style={{ paddingTop: 10 }} >
													<Grid container justifyContent="center" alignItems="center">
														<Grid item >
													 		<ArgonTypography >Upload</ArgonTypography>
														</Grid>
														{ uploadSend &&
															<Grid item ml={1}>
																<CheckCircle fontSize="medium" sx={{ color: success.main }} />
															</Grid>
														}
													</Grid>
												</Grid>
												<Divider mt={0} style={{ width: '100%', height: '2px', margin: '0.2rem' }} />
												<Grid item xs={12} style={{ paddingTop: 8 }} >
													<ArgonAvatar src={logoBoxingPNG} alt="upload logo" variant="rounded" sx={{ width: "10vw", height: "10vw", margin: "25px" }}
																 onMouseOver={(e) => e.target.src = logoBoxingGIF}
																 onMouseOut={(e) => e.target.src = logoBoxingPNG}/>
												</Grid>
											</Grid>
										</ArgonBox>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
						<Grid item sx={(cardDataLakeSelected || cardManagementToolSelected || cardUploadSelected) ? {display: "none"} : {}}>
							<Card sx={ datalakeSend ? {outline: 1, outlineColor: "primary.main"} : {}} onClick={() => onCardClick("Data Lake")}>
								<CardActionArea>
									<CardContent>
										<ArgonBox>
											<Grid container alignItems="center" spacing={2} direction="column">
												<Grid item xs style={{ paddingTop: 10 }} >
													<Grid container justifyContent="center" alignItems="center">
														<Grid item >
															<ArgonTypography >Data Lake</ArgonTypography>
														</Grid>
														{ datalakeSend &&
															<Grid item ml={1}>
																<CheckCircle fontSize="medium" sx={{ color: success.main }} />
															</Grid>
														}
													</Grid>
												</Grid>
												<Divider mt={0} style={{ width: '100%', height: '2px', margin: '0.2rem' }} />
												<Grid item xs={12} style={{ paddingTop: 8 }} >
													<ArgonAvatar src={logoDBTablePNG} alt="data-lake logo" variant="rounded" sx={{ width: "10vw", height: "10vw", margin: "25px" }}
																 onMouseOver={(e) => e.target.src = logoDBTableGIF}
																 onMouseOut={(e) => e.target.src = logoDBTablePNG}/>
												</Grid>
											</Grid>
										</ArgonBox>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
						<Grid item sx={(cardDataLakeSelected || cardManagementToolSelected || cardUploadSelected) ? {display: "none"} : {}}>
							<Card sx={ managementSend ? {outline: 1, outlineColor: "primary.main"} : {}} onClick={() => onCardClick("Management Tool")}>
								<CardActionArea>
									<CardContent>
										<ArgonBox>
											<Grid container alignItems="center" spacing={2} direction="column">
												<Grid item xs style={{ paddingTop: 10 }} >
													<Grid container justifyContent="center" alignItems="center">
														<Grid item >
															<ArgonTypography >Tool</ArgonTypography>
														</Grid>
														{ managementSend &&
															<Grid item ml={1}>
																<CheckCircle fontSize="medium" sx={{ color: success.main }} />
															</Grid>
														}
													</Grid>
												</Grid>
												<Divider mt={0} style={{ width: '100%', height: '2px', margin: '0.2rem' }} />
												<Grid item xs={12} style={{ paddingTop: 8 }} >
													<ArgonAvatar src={logoManagementToolPNG} alt="management-tool logo" variant="rounded" sx={{ width: "10vw", height: "10vw", margin: "25px" }}
																 onMouseOver={(e) => e.target.src = logoManagementToolGIF}
																 onMouseOut={(e) => e.target.src = logoManagementToolPNG}/>
												</Grid>
											</Grid>
										</ArgonBox>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					</Grid>
				</ArgonBox>
			</ArgonBox>
			<Modal open={openModalSendButton}
				   onClose={(event, reason) => {
					   if (reason !== 'backdropClick') {
						   handleCloseModal(event, reason);
					   }
				   }}
				   closeAfterTransition
				   slots={{ backdrop: Backdrop }}
				   slotProps={{
					   backdrop: {
						   timeout: 500,
					   },
				   }}
				   aria-labelledby="send-generative"
				   aria-describedby="send-generative">
				<ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
					<Grid container justifyContent="center">
						{bodyGenerate &&
							<Grid item xs={10} sm={10} lg={5} borderRadius={4}>
								<Card>
									<ArgonBox p={3}>
										<ArgonTypography variant="h4" textAlign={"center"}>
											What do you want to do?
										</ArgonTypography>
									</ArgonBox>
									<ArgonBox mt={3} mb={13}>
										<Grid container justifyContent="center">
											<Grid item width="50%">
												<ArgonSelect
													placeholder="Select a choice"
													options={
														[{ value: "testbook", label: "Generate Testbook" },
														{ value: "gherkintest", label: "Generate Gherkin" },
														{ value: "upload_testbook", label: "Import Testbook" },
														{ value: "upload_gherkintest", label: "Import Gherkin" },
														]}
													onChange={(e) => setChangeSendType(e)}
												/>
											</Grid>
										</Grid>
									</ArgonBox>
									<ArgonBox mb={5}>
										<Grid container spacing={1} justifyContent="center">
											<Grid item>
												<ArgonButton color="dark" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>
											</Grid>
											<Grid item>
												<ArgonButton color="info" variant="gradient" disabled={generateType === null} onClick={() => sendButtonAction()}>Yes, generate!</ArgonButton>
											</Grid>
										</Grid>
									</ArgonBox>
								</Card>
							</Grid>
						}
						{bodyRefresh &&
							<Grid item xs={10} sm={10} lg={5} borderRadius={4}>
								<Card>
									<ArgonBox p={3}>
										<ArgonTypography variant="h4" textAlign={"center"}>
											Are you sure?
										</ArgonTypography>
										<ArgonTypography variant="h6" textAlign={"center"} pt={1}>
											All documents and information will be lost!
										</ArgonTypography>
									</ArgonBox>
									<ArgonBox mb={5} mt={3}>
										<Grid container spacing={1} justifyContent="center">
											<Grid item>
												<ArgonButton color="dark" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>
											</Grid>
											<Grid item>
												<ArgonButton color="error" variant="gradient" onClick={() => refreshPage()}>Yes, refresh!</ArgonButton>
											</Grid>
										</Grid>
									</ArgonBox>
								</Card>
							</Grid>
						}
					</Grid>
				</ArgonBox>
			</Modal>
			{cardUploadSelected &&
				<Zoom in={cardUploadSelected}>
					<Paper elevation={5} sx={{boxShadow: '0px 0px 0px !important', backgroundColor: 'transparent !important'}}>
						<GenerativeTestFileUploader setCardUploadSelected={setCardUploadSelected} setUploadSend={setUploadSend} dropzoneFiles={dropzoneFiles}/>
					</Paper>
				</Zoom>
			}
			{cardDataLakeSelected &&
				<Zoom in={cardDataLakeSelected}>
					<Paper elevation={5} sx={{boxShadow: '0px 0px 0px !important', backgroundColor: 'transparent !important'}}>
						<DataLakeInfo setCardDataLakeSelected={setCardDataLakeSelected} setDatalakeSend={setDatalakeSend}/>
					</Paper>
				</Zoom>
			}
			{cardManagementToolSelected &&
				<Zoom in={cardManagementToolSelected}>
					<Paper elevation={5} sx={{boxShadow: '0px 0px 0px !important', backgroundColor: 'transparent !important'}}>
						<ManagementToolInfo setCardManagementToolSelected={setCardManagementToolSelected} user={user} userIntegrations={userIntegrations} setManagementSend={setManagementSend}
											jira={{connectedJira, setConnectedJira, dataJira, setDataJira, projectsJira, setProjectsJira, jiraFiles}}/>
					</Paper>
				</Zoom>
			}
			{(uploadSend || datalakeSend || managementSend) && !(cardDataLakeSelected || cardManagementToolSelected || cardUploadSelected) && !generateSend &&
				<ArgonBox mt={10} ml={35} mr={35} mb={15}>
					<ArgonButton
						id="sendButton"
						size="medium"
						endIcon={<AutoFixHigh />}
						disabled={!(uploadSend || datalakeSend || managementSend)}
						variant="gradient"
						color="info"
						onClick={handleOpenModal}
						fullWidth
					>
						<span>Generate</span>
					</ArgonButton>
					<ArgonBox mt={2}>
						<ArgonButton
							id="refreshButton"
							size="medium"
							endIcon={<Refresh />}
							disabled={!(uploadSend || datalakeSend || managementSend)}
							variant="gradient"
							color="error"
							onClick={(handleOpenRefreshModal)}
							fullWidth
						>
							<span>Refresh</span>
						</ArgonButton>
					</ArgonBox>
				</ArgonBox>
			}
			{uploadProgress >= 0 &&
				<ArgonBox mt={25} mb={25} sx={{ flexGrow: 1 }}>
				 	<ArgonProgress value={uploadProgress} variant="gradient" label={true} sx={{ height: '1em' }} />
				</ArgonBox>
			}
			{generateSend &&
				<GenerativeTestTable user={user} tests={tests} setTests={setTests} setLoading={setLoading}
									 setUploadProgress={setUploadProgress} setGenerateSend={setGenerateSend} setGenerativeTestError={setGenerativeTestError}
									 dropzoneFiles={dropzoneFiles} jiraFiles={jiraFiles} generateType={generateType}/>
			}
			{generativeTestError &&
				<ArgonBox mb={3} mt={5}>
					<Card sx={{ minHeight: "calc(100vh - 90vh)", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "error.main" }}>
						<ArgonTypography variant="h1" color="white">ERROR</ArgonTypography>
					</Card>
					<ArgonBox mt={5} mb={5} ml={20} mr={20}>
						<ArgonButton fullWidth variant="contained" color="dark" onClick={() => handleBack()}> Back </ArgonButton>
					</ArgonBox>
				</ArgonBox>
			}
			<Footer />
		</DashboardLayout>
	);
}


