import {Grid, Card, CardActions, CardHeader} from "@mui/material";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import logoManagementToolPNG from "assets/images/icons/management-consulting.png";
import ArgonButton from "../../../components/ArgonButton";

import {useEffect, useState} from "react";
import {JiraTool} from "./JiraTool";
import {FigmaTool} from "./FigmaTool";
import {ADOTool} from "./ADOTool";

export function ManagementToolInfo({setCardManagementToolSelected, userIntegrations, setManagementSend, jira}) {

	return (<ArgonBox mt={15} mb={10}>
				<Grid container justifyContent="center">
					<Grid item xs={12} md={8} mb={5}>
						<Card>
							<Grid container justifyContent="center" alignItems="center">
								<Grid item >
									<ArgonBox>
										<img src={logoManagementToolPNG} style={{width: "3vw", height: "3vw"}} alt="logoManagementTool"/>
									</ArgonBox>
								</Grid>
								<Grid item >
									<ArgonBox>
										<ArgonTypography component="label" variant="h5" fontWeight="bold">
											Tool
										</ArgonTypography>
									</ArgonBox>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={6}>
						<ArgonBox mb={5} ml={5} mr={5} textAlign="center">
							<JiraTool userIntegrations={userIntegrations}
									  setManagementSend={setManagementSend}
									  jira={jira}
									  expandedDialog={false}
									  module={"Generative"}
									  otherInfo={null}/>
						</ArgonBox>
					</Grid>
					<Grid item xs={6}>
						<ArgonBox mb={5} ml={5} mr={5} textAlign="center">
							<FigmaTool userIntegrations={userIntegrations} setManagementSend={setManagementSend}/>
						</ArgonBox>
					</Grid>
					<Grid item xs={6}>
						<ArgonBox mb={5} ml={5} mr={5} textAlign="center">
							<ADOTool userIntegrations={userIntegrations} setManagementSend={setManagementSend}/>
						</ArgonBox>
					</Grid>
					<Grid item xs={6}>
						<ArgonBox mb={5} ml={5} mr={5} textAlign="center">
						</ArgonBox>
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={12} md={8} mb={5}>
						<ArgonBox mt={3} ml={5} mr={5}>
							<ArgonButton fullWidth variant="contained" color="dark" onClick={() => setCardManagementToolSelected(false)}> Back </ArgonButton>
						</ArgonBox>
					</Grid>
				</Grid>
			</ArgonBox>);
} 


