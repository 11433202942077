import React, { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";

function PageSelection({ pagesLocator, pagesForSchedule, setPagesForSchedule }) {

    const handleCardClick = useCallback((page) => {
        const updatePages = (prevPages) =>
            prevPages.includes(page)
                ? prevPages.filter((selectedPage) => selectedPage !== page)
                : [...prevPages, page];

        setPagesForSchedule(updatePages);
    }, []);

    return (
        <Grid container width="100vh" margin="0 auto" spacing={2}>
            {pagesLocator
                .filter((page) => page.page !== "" && page.level === "1")
                .map((page) => {
                const isSelected = pagesForSchedule.includes(page);
                const cardStyles = {
                    height: "20vh",
                    width: "20vh",
                    outline: isSelected ? "2px solid" : "none",
                    outlineColor: isSelected ? "primary.main" : "none",
                };
                const cardContentStyles = {
                    backgroundColor: isSelected ? "primary.main + 09" : "inherit",
                };

                return (
                    <Grid item xs={3} key={page._id} mb={2}>
                        <Card sx={cardStyles}>
                            <CardActionArea onClick={() => handleCardClick(page)}>
                                <CardContent sx={cardContentStyles}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ height: "5vh" }}>
                                            <Grid container justifyContent="center">
                                                {page.project && (
                                                    <Grid item textAlign="center" pr={1}>
                                                        <ArgonBadge
                                                            badgeContent={page.project}
                                                            variant="gradient"
                                                            size="xs"
                                                            color="secondary"
                                                            container
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ height: "10vh" }}>
                                            <ArgonBox p={2} textAlign="center">
                                                <ArgonTypography variant="h6" fontWeight="medium">
                                                    {page.page_title}
                                                </ArgonTypography>
                                            </ArgonBox>
                                        </Grid>
                                        <Grid item xs={12} sx={{ height: "5vh" }}>
                                            <Grid container justifyContent="center">
                                                {page.capability && (
                                                    <Grid item textAlign="center" pr={1}>
                                                        <ArgonBadge
                                                            badgeContent={page.capability}
                                                            variant="gradient"
                                                            size="xs"
                                                            color="warning"
                                                            container
                                                        />
                                                    </Grid>
                                                )}
                                                {page.market && (
                                                    <Grid item textAlign="center" pr={1}>
                                                        <ArgonBadge
                                                            badgeContent={page.market}
                                                            variant="gradient"
                                                            size="xs"
                                                            color="dark"
                                                            container
                                                        />
                                                    </Grid>
                                                )}
                                                {page.env && (
                                                    <Grid item textAlign="center" pr={1}>
                                                        <ArgonBadge
                                                            badgeContent={page.env}
                                                            variant="gradient"
                                                            size="xs"
                                                            color="info"
                                                            container
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default PageSelection;
