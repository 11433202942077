import apiClient from "api/BackendApi";

const Locator = {
  getLocatorPages:  () => apiClient.get("/be/locator/pages"),
  getLocatorPagesByFilters:  (project, capability, market, env) => apiClient.post("/be/locator/pagesByFilters",{"project": project, "capability": capability, "market": market, "env": env}),
  getLocators:  (pageTitle, page, project) => apiClient.post("/be/locator", {"pageTitle": pageTitle, "page": page, "project": project}),
  getLocatorImage:  (imageId) => apiClient.post("/be/locator/getImage", {"imageId": imageId}, {responseType: 'blob'}),
  createLocatorPageOnDB:  (pageTitle, page, project, capability, market, env, level) => apiClient.post("/be/locator/newPage", {"pageTitle": pageTitle, "page": page, "project": project, "capability": capability, "market": market, "env": env, "level": level}),
  createLocatorOnDB:  (page, name, xpath, xpathParentLocator, componentLocator, priorityLocator, actionLocator) => apiClient.post("/be/locator/new", {"page": page, "name": name, "xpath": xpath, "xpathParentLocator": xpathParentLocator, "componentLocator": componentLocator, "priorityLocator": priorityLocator, "actionLocator": actionLocator}),
  updateLocatorOnDB:  (page, originalData, newData) => apiClient.post("/be/locator/update", {"page": page, "originalData": originalData, "newData": newData}),
  deleteLocatorOnDB:  (page, nameLocator) => apiClient.post("/be/locator/delete", {"page": page, "nameLocator": nameLocator}),
  deleteLocatorPageOnDB:  (pageTitle, page, project) => apiClient.post("/be/locator/deletePage", {"pageTitle": pageTitle, "page": page, "project": project}),
  updatePageTitle:  (pageId, pageTitle, filter) => apiClient.post("/be/locator/updatePageTitle", {"pageId": pageId, "pageTitle": pageTitle, "filter": filter}),
  updatePage:  (pageId, page) => apiClient.post("/be/locator/updatePage", {"pageId": pageId, "page": page}),
  scheduleLocatorMonitoringOnDB: (pagesForSchedule,scheduleInfo) => apiClient.post("/be/locator/scheduleMonitoring", {"pagesForSchedule": pagesForSchedule, "scheduleInfo": scheduleInfo}),
  deleteSecondaryLocatorOnDB: (page, locator, xpath) => apiClient.post("/be/locator/deleteSecondaryXpath", {"page": page, "locator": locator, "xpath": xpath}),
  changePrincipalLocatorOnDB: (page, locator, xpath) => apiClient.post("/be/locator/changePrincipalXpath", {"page": page, "locator": locator, "xpath": xpath}),
  saveNewInformation: (page, locator, xpathParentLocator, componentLocator, priorityLocator, actionLocator) => apiClient.post("/be/locator/saveNewInformation", {"page": page, "locator": locator, "xpathParentLocator": xpathParentLocator, "componentLocator": componentLocator, "priorityLocator": priorityLocator, "actionLocator": actionLocator}),
}

export {
  Locator
};
